import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { Box, Button, Grid } from '@mui/material';
import { PieChart } from "@mui/x-charts";
import { Typography } from "@mui/material";

const supabase = createClient("https://qlewrjcbsyypecovgduy.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFsZXdyamNic3l5cGVjb3ZnZHV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTMxOTIyNTcsImV4cCI6MjAyODc2ODI1N30.roZmVGylQaTV7hl-8MEeoxnGoajhfF0chQ4tM8VAvqc");

function App() {
  const [slots, setslots] = useState([]);

  useEffect(() => {
    getslots();
  }, []);

  async function getslots() {
    const { data } = await supabase.from("parkingdb").select().order("id");
    setslots(data);
  }

  async function increment(slot, count) {
    await supabase
      .from("parkingdb")
      .update({ current_capacity: slot.current_capacity + count })
      .eq("id", slot.id);
    getslots();
  }
  async function decrement(slot, count) {
    await supabase
      .from("parkingdb")
      .update({ current_capacity: slot.current_capacity - count })
      .eq("id", slot.id);
    getslots();
  }

  //update every second
  useEffect(() => {
    const interval = setInterval(() => {
      getslots();
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      <Typography variant="h2" sx={{ padding: '10px' }}>Parking Management System</Typography>
      <Typography variant='h5' sx={{ paddingX : '10px' ,  color : '#FFFFFF'}}>Made by - SYN4CK Team</Typography>
      <Grid container spacing={2} sx={{ padding: '10px' }}>
        {slots.map((slot) => (
          <Box key={slot.id} sx={{ border: '1px solid black', padding: '10px', borderRadius: '10px', margin: '10px' }}>
            <Typography variant="h4">{slot.name}</Typography>
            <Typography variant="h6">Location : {slot.campus}</Typography>
            <Typography variant="h6">Currently Occupied : {slot.current_capacity}</Typography>
            <Typography variant="h6">Total Capacity : {slot.capacity}</Typography>
            <PieChart sx={{ margin: '10px' }} 
            series={[{
              data: [
                { id: 0, value: slot.capacity - slot.current_capacity, label: 'Vacant' , color: '#00bb00' },
                { id: 1, value: slot.current_capacity, label: 'Occupied' , color: '#da0000'},
              ],
            },
            ]}
              width={400}
              height={200}
            />
            <Button sx={{margin : '10px'}} variant='contained' onClick={() => increment(slot, 1)}><strong>+1</strong></Button>
            <Button sx={{margin : '10px'}} variant='contained' onClick={() => decrement(slot, 1)}><strong>-1</strong></Button>

            <Button sx={{margin : '10px'}} variant='contained' onClick={() => increment(slot, 5)}><strong>+5</strong></Button>
            <Button sx={{margin : '10px'}} variant='contained' onClick={() => decrement(slot, 5)}><strong>-5</strong></Button>

            <Button sx={{margin : '10px'}} variant='contained' onClick={() => increment(slot, 10)}><strong>+10</strong></Button>
            <Button sx={{margin : '10px'}} variant='contained' onClick={() => decrement(slot, 10)}><strong>-10</strong></Button>
          </Box>

        ))}
      </Grid>
    </Box>
  );
}

export default App;